<template>
  <v-dialog
    v-model="open"
    persistent
    max-width="600px"
  >
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="random-string"
      class="dialogForm"
    >
      <v-card
        outlined
        tile
      >
        <v-toolbar
          color="blue lighten-4"
          dense
        >
          <v-toolbar-title class="text-subtitle-1">{{ title }} </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="档号"
                  dense
                  v-model="config.record.record_no"
                  :rules="validators.record_no"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="文件编号"
                  dense
                  v-model="config.record.file_no"
                  :rules="validators.file_no"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="实体分类号"
                  dense
                  v-model="config.record.entity_catelog_no"
                  @click.stop="openCategoryDialog"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  dense
                  hide-details="true"
                  item-text="name"
                  item-value="value"
                  :items="systemDataGroups['生态环境要素']"
                  v-model="config.record.huanjingyaosuleimu"
                  label="生态环境要素"
                  clearable
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  dense
                  hide-details="true"
                  item-text="name"
                  item-value="value"
                  :items="systemDataGroups['保管期限']"
                  v-model="config.record.keepterm"
                  label="保管期限"
                  clearable
                ></v-select>
              </v-col>

            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="顺序号"
                  dense
                  v-model="config.record.order_index"
                  :rules="validators.order_index"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="页号"
                  dense
                  v-model="config.record.page_no"
                  :rules="validators.page_no"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="页数"
                  dense
                  v-model="config.record.page_count"
                  :rules="validators.page_count"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-select
                  dense
                  hide-details="true"
                  item-text="name"
                  item-value="value"
                  :items="systemDataGroups['稿本']"
                  v-model="config.record.gaoben"
                  :rules="validators.gaoben"
                  label="稿本"
                ></v-select>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  label="机构名称"
                  dense
                  v-model="config.record.organization"
                  :rules="validators.organization"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="题名"
                  v-model="config.record.name"
                  :rules="validators.name"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="主题词"
                  dense
                  v-model="config.record.title"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-menu
                  ref="fileStartDialog"
                  v-model="fileStartDialog"
                  persistent
                  transition="scale-transition"
                  offset-y
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatedfileStartDate"
                      label="文件形成时间"
                      readonly
                      dense
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="config.record.archive_date"
                    no-title
                    @input="fileStartDialog = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="责任者"
                  dense
                  v-model="config.record.charger"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="备注"
                  dense
                  v-model="config.record.note"
                  :rows="2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="open = false"
          >取消</v-btn>
          <v-btn
            color="warning"
            :disabled="!valid"
            @click="createOrEdit()"
          >确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <category-tree-select-dialog
      ref="_category_dialog"
      :cateno.sync="config.record.entity_catelog_no"
    ></category-tree-select-dialog>
  </v-dialog>
</template>

<script>
import { useArchiveStore } from "@/stores/archiveStore.js";
import { useRecordStore } from "@/stores/recordStore.js";
export default {
  name: "RecordEditDialog",
  data() {
    return {
      open: false,
      loading: false,
      title: "",
      config: {
        record: {
          record_no: "",
          order_index: 1,
        },
        rules: [],
      },
      valid: false,
      fileStartDialog: false,
      systemDataGroups: [],
      validators: {
        record_no: [(v) => !!v || "请填写档号"],
        fil_no: [(v) => !!v || "请填写文件编号"],
        name: [(v) => !!v || "请填写题名"],
        order_index: [(v) => /^\d*$/.test(v || "0") || "请填写数字"],
        page_no: [(v) => /^\d*$/.test(v || "0") || "请填写数字"],
        page_count: [(v) => /^\d*$/.test(v || "0") || "请填写数字"],
        gaoben: [(v) => !!v || "请选择稿本"],
        organization: [(v) => !!v || "请填写机构名称"],
      },
    };
  },
  computed: {
    formatedfileStartDate() {
      return this.config.record && this.config.record.archive_date
        ? this.$moment(
            this.config.record.archive_date,
            "YYYY-MM-DDTHH:mm:ss"
          ).format("YYYY-MM-DD")
        : "";
    },
  },
  watch: {
    "config.record.order_index": {
      handler() {
        if (this.open) {
          this.processDocumentNo();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchSystemDataGroup();
  },
  methods: {
    openDialog(config) {
      this.config = config;
      if (this.config.type === 0) {
        this.title = "新建档案";
      } else {
        this.title = "编辑档案";
      }
      this.processDocumentNo();
      this.open = true;
    },
    processDocumentNo() {
      const store = useArchiveStore();
      if (this.config.record.order_index) {
        var idx=((this.config.record.order_index||'')+'').padStart(4,0);
        this.config.record.record_no = `${store.active_archive.archive_no}-${idx}`;
      } else {
        this.config.record.record_no = `${store.active_archive.archive_no}`;
      }
    },
    fetchSystemDataGroup() {
      this.$hc
        .req()
        .get(`systemdata/groups?type=systemdictionary`)
        .go({
          lock: true,
          toastError: true,
          toastSuccess: "",
        })
        .subscribe(
          (x) => {
            this.systemDataGroups = x.Data;
          },
          (x) => {}
        );
    },
    openCategoryDialog() {
      this.$refs._category_dialog.openDialog();
    },
    createOrEdit() {
      if (!this.$refs.form.validate()) return;
      const store = useRecordStore();
      store.createOrUpdate(this.config.record);
      this.open = false;
    },
  },
};
</script>
